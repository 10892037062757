import React from "react";
import './App.css';
import Terms from './Terms';
import {BrowserRouter, Route, Link, Routes} from 'react-router-dom';
import * as PropTypes from "prop-types";
import Privacy from "./Privacy.js";
import CookieConsent, { Cookies } from "react-cookie-consent";
import FadeImage from "./FadeImage.js";

const App = () => {
    const Home = () =>
        <section className="poemy-description">
            <p className="big">Yourself, in a poem</p>
            <p>Instant poetry about your life! Convert WhatsApp chats with friends into beautiful, personalized poems</p>
            <a href="https://wa.me/17187177868?text=Show%20me%20the%20Poemy%20magic"><button className="poemy-connect-button">Get 10 poems for free</button></a>
        </section>

    const RefillSuccess = () =>
        <section className="poemy-description">
            <p className="big">Congratulations! Your payment is being processed and your Poemy credits will be updated soon. Get ready to immerse yourself in the world of poems and spread the love 🎉</p>
            <a href="https://wa.me/17187177868?text="><button className="poemy-connect-button">Continue to WhatsApp</button></a>
        </section>

    const RefillFail = () =>
        <section className="poemy-description">
            <p className="big">Payment failed. Please check your details and try again or reach out to our support team for help.</p>
            <a href="https://wa.me/17187177868?text="><button className="poemy-connect-button">Continue to WhatsApp</button></a>
        </section>


  return (
      <BrowserRouter>
      <div className="poemy-container">
        <header>
            <Link to="/">
          <img src="/favicon.png" alt="Poemy logo" className="poemy-logo" />
          <h1>Poemy</h1>
            </Link>
        </header>
              <Routes>
                  <Route path="/" exact element={<Home />} />
                  <Route path="/terms-and-conditions" element={<Terms />} />
                  <Route path="/privacy-policy" element={<Privacy />} />
                  <Route path="/refill/success" element={<RefillSuccess />} />
                  <Route path="/refill/fail" element={<RefillFail />} />
              </Routes>
          <FadeImage className="poemy-love" urls={['/love.png','/love2.png','/love3.png']} />
      </div>
          <footer className="poemy-footer">
              <section className="poemy-footer-about">
                  <p>Poemy is a WhatsApp bot that generates poems out of your chats and group chats. Using OpenAI technology, Poemy can create personalized poetry that reflects the emotions and themes present in your chats.</p>
                  <p>Connect with Poemy now and let the magic of poetry unfold.</p>
              </section>
              <section className="poemy-footer-links">
                  <p>© 2023 All rights reserved to Baratz development</p>
                  <Link to="/terms-and-conditions">Terms and Conditions</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>
              </section>
          </footer>
      </BrowserRouter>
  );
}

export default App;
