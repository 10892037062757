import React, { useState, useEffect } from 'react';

const FadeImage = ({ urls, className }) => {
    const [currentUrl, setCurrentUrl] = useState(0);

    useEffect(()=> {
        for(let url of urls)
            preloadImage(url);
    },[]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentUrl((currentUrl + 1) % urls.length);
        }, 3500);
        return () => clearInterval(intervalId);
    }, [currentUrl, urls]);

    return (
        <div className={className}
            style={{
                width: '90%',
                maxWidth: '400px',
                height: '400px',
                backgroundImage: `url(${urls[currentUrl]})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                transition: 'background-image 1s ease-in-out',
                display: 'inline-block'
            }}
        />
    );
};

const preloadImage = (url) => {
    const image = new Image();
    image.src = url;
};


export default FadeImage;
