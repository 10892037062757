import React from 'react';

const Terms = () => {
  return (
        <section className="poemy-section terms">
            <h1>Terms and Conditions</h1>
            <p>Welcome to Poemy, the WhatsApp bot that generates poetry out of your chats and group chats. By using our service, you agree to be bound by the following terms and conditions.</p>
            <h2>Use of Service</h2>
            <p>You must be at least 18 years of age to use our service. You must also have a valid WhatsApp account to use Poemy. You are responsible for ensuring that your use of Poemy complies with all applicable laws and regulations.</p>
            <h2>Privacy Policy</h2>
            <p>We take your privacy seriously and will only use your personal information as described in our Privacy Policy. By using Poemy, you consent to the collection, use and sharing of your personal information as described in our Privacy Policy.</p>
            <h2>Intellectual Property</h2>
            <p>The content generated by Poemy, including but not limited to the poems, are the property of Poemy, however Poemy grants you a worldwide, non-exclusive, royalty-free, sublicensable and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the content generated by Poemy for any purpose, including commercial use. </p>
            <h2>Disclaimers and Limitations of Liability</h2>
            <p>Our service is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of our service or the content generated by Poemy. We will not be liable for any damages of any kind arising from the use of our service or the content generated by Poemy, including but not limited to direct, indirect, incidental, punitive and consequential damages.</p>
            <h2>Modifications to Terms and Conditions</h2>
            <p>We reserve the right to modify these terms and conditions at any time. Your continued use of Poemy following any changes to these terms and conditions will be deemed to be your acceptance of such changes.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about these terms and conditions, please contact us at info@poemy.com.</p>
        </section>
  );
}

export default Terms;
