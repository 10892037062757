import React from 'react';

const Privacy = () => {
  return (
        <section className="poemy-section privacy">
            <h1>Privacy Policy</h1>
            <p>At Poemy, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you use our WhatsApp bot and our website.</p>

            <h2>Information We Collect</h2>
            <p>When you use Poemy, we may collect the following types of information:</p>
            <ul>
                <li>Information you provide to us directly, such as your WhatsApp number, when you register for an account with Poemy.</li>
                <li>Information about your use of Poemy, such as the frequency of use.</li>
                <li>Information about the device you use to access Poemy, such as your IP address, device type, and browser type.</li>
            </ul>
            <p>Please note that we do not collect or store the chats used to generate poems. The chats are temporarily stored in a non-persistent memory for a few minutes, solely for the purpose of allowing users to regenerate a new version of the poem if desired. However, the chats are not saved or stored in any way, and are not collected as data by Poemy.</p>

            <h2>How We Use Your Information</h2>
            <p>We may use the information we collect from you for the following purposes:</p>
            <ul>
                <li>To provide and improve Poemy's service.</li>
                <li>To personalize your experience with Poemy, such as by providing customized content and recommendations.</li>
                <li>To communicate with you about your use of Poemy, such as to send you updates or promotional messages.</li>
                <li>To analyze and understand how Poemy is being used, in order to improve and enhance the service.</li>
            </ul>

            <h2>Sharing Your Information</h2>
            <p>We do not sell or share your personal information with third parties for their own marketing purposes without your consent. We may share your information in the following ways:</p>
            <ul>
                <li>With our service providers and partners, who assist us in providing Poemy's service.</li>
                <li>With law enforcement or other government agencies, as required by law or as necessary to protect the rights and safety of Poemy and its users.</li>
            </ul>

            <h2>Your Rights</h2>
            <p>You have the right to access, correct, and delete the personal information that we have collected about you. You also have the right to object to the processing of your personal information and to request that your personal information be transferred to another controller.</p>

            <h2>Changes to Our Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. If we make changes, we will notify you.</p>
            </section>
  );
}

export default Privacy;
